<template>
    <div class="bridge">
        <router-view/>
        <Nav />
    </div>
</template>

<script>
import Nav from '@/components/nav.vue'
// import '../../authority/bridge.js'
export default {
    components:{
        Nav:Nav
    },
    data(){
        return{}
    }
}
</script>

<style lang="scss">
@import '../../assets/bridge/index.scss'
</style>>
